import { useContext, useEffect, useState } from 'react';

import './SearchProductPopup.css';
import AdaptivePopup from '../../../../../../../../AdaptivePopup/AdaptivePopup';
import Button from '../../../../../../../../Button/Button';
import PlatformInput from '../../../../../../../PlatformInput/PlatformInput';
import { handleChange } from '../../../utils/editMainPageHelpers';
import publicApi from '../../../../../../../../../assets/api/PublicApi';
import { UserContext } from '../../../../../../../../../assets/contexts/userContext';
import { StringOrNothingFormater, getMinPrice, parsePrice } from '../../../../../../../../../assets/utils/utils';
import PreloaderBox from '../../../../../../../../PreloaderBox/PreloaderBox';


const DEFAULT_FORM = {
    querry: '',
}








function SearchProductPopup({ isOpen, handleClose, block, handleChangeBlock }) {
    const { user } = useContext(UserContext)
    const [form, setForm] = useState(DEFAULT_FORM)
    const [isPreloaderVisible, setIsPreloaderVisible] = useState({
        search: false,
    })

    const [searchItems, setSearchItems] = useState(null)


    function handleChange(e) {
        const value = e.target.value
        const name = e.target.name
        switch (name) {
            case 'querry': {
                if (!value) {
                    setSearchItems(null)
                }
                setIsPreloaderVisible(prevValue => ({
                    ...prevValue,
                    search: Boolean(value),
                }))
                setForm(prevValue => ({
                    ...prevValue,
                    [name]: value,
                }))
                break;
            }
            default: {
                setForm(prevValue => ({
                    ...prevValue,
                    [name]: value,
                }))
                break;
            }
        }
    }
    function onClosePopup() {
        handleClose()
        setTimeout(() => {
            setForm(DEFAULT_FORM)
            setSearchItems(null)
        }, 300);
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            console.log(form.querry)
            if (!form.querry) return

            const shop_id = user.default_shop._id
            const isMongoId = /^[0-9a-fA-F]{24}$/.test(form.querry)
            const existingIds = block.data[0].products && block.data[0].products.length > 0 ? block.data[0].products.map(product => product._id) : [];

            if (isMongoId) {
                // If querry is in Mongo ObjectID format, do a different API request
                publicApi.getExactProduct({
                    shop_id: shop_id,
                    _id: form.querry,
                })
                    .then((res) => {
                        console.log(res)

                        if (!existingIds.includes(res._id)) {
                            setSearchItems([res]) // Assuming response is a single item
                        }

                    })
                    .catch((err) => {
                        console.log(err)
                    })
                    .finally(() => {
                        setIsPreloaderVisible((prevValue) => ({
                            ...prevValue,
                            search: false,
                        }))
                    })
            } else {
                // Original search logic
                publicApi.getSearch({
                    shop_id: shop_id,
                    string: form.querry.toLowerCase(),
                    limit: 3,
                })
                    .then((res) => {
                        console.log(res.data)

                        // Filter out any products that are already in block.data[0].products
                        const filtered_data = res.data.filter(product => {
                            return !existingIds.includes(product._id);
                        });

                        setSearchItems(filtered_data);
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                    .finally(() => {
                        setIsPreloaderVisible((prevValue) => ({
                            ...prevValue,
                            search: false,
                        }))
                    })
            }

        }, 500)

        return () => clearTimeout(delayDebounceFn)
    }, [form.querry])

    function handleSelectItem(item) {
        onClosePopup()
        handleChangeBlock({
            target: {
                index: 0,
                value: item,
                item: block,
                name: 'show-case',
                subname: 'push-product',
            }
        })
    }
    return (
        <AdaptivePopup
            isOpen={isOpen}
            handleClose={onClosePopup}
            popupName={'searchProduct'}
        >
            <div className='search-product-popup'>
                <p className='search-product-popup__title'>Поиск товара</p>
                <PlatformInput
                    value={form.querry}
                    handleChange={handleChange}
                    label={'Название или ID'}
                    type={'text'}
                    inputMode='text'
                    name={'querry'}
                    show_eror_color_only

                // error={!zone.distance_km}
                />
                {console.log({ searchItems })}
                <div className='search-product-popup__items'>
                    {
                        !isPreloaderVisible.search ?
                            searchItems && searchItems.length > 0 ?
                                searchItems.map((item, i) => {
                                    const price = item.options && item.options.length === 1 ? getMinPrice(item.options[0].values) : item.price_data.price
                                    return (
                                        <button className='search-product-popup__item' key={`search-product-popup__item${i}${item._id}`} type='button' onClick={() => {
                                            handleSelectItem(item)
                                        }}>
                                            <div className='search-product-popup__item-info'>
                                                {item.files && item.files.length !== 0 ? (
                                                    <img
                                                        className="search-product-popup__item-img"
                                                        src={item.files[0]?.urls?.orig}
                                                        alt=""
                                                    />
                                                ) : (
                                                    <div className="search-product-popup__item-img search-product-popup__item-img-placeholder" />
                                                )}
                                                <div className='search-product-popup__item-name-info'>
                                                    <h5 className="search-product-popup__item-title">
                                                        {StringOrNothingFormater(item.name)}
                                                    </h5>
                                                    <p className="search-product-popup__item-info-text">
                                                        {item.options && item.options.length === 1 ? 'от ' : ''}{price ? parsePrice(price, 'RUB') : ''}
                                                    </p>
                                                </div>
                                            </div>
                                        </button>
                                    );
                                })
                                :
                                form.querry ? <p className='search-product-popup__no-item'>Ничего не найдено</p> : null
                            :
                            <PreloaderBox />
                    }
                </div>
            </div>
        </AdaptivePopup>
    );
}

export default SearchProductPopup