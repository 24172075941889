import { useContext, useEffect, useState } from 'react';

import './EditOrderPopup.css';
import AdaptivePopup from '../../../../../AdaptivePopup/AdaptivePopup';
import { ORDER_AVAILIBLE_FOR_EDIT_STATUSES } from '../../../../../../assets/utils/constants';
import { StringOrNothingFormater, deepEqual, parsePrice, parseStatus } from '../../../../../../assets/utils/utils';
import Button from '../../../../../Button/Button';
import MiniPreloader from '../../../../../MiniPreloader/MiniPreloader';
import mainApi from '../../../../../../assets/api/MainApi';
import { UserContext } from '../../../../../../assets/contexts/userContext';





function EditOrderPopup({ isOpen, handleClose, order, items, isMoreBtnVisible, isFeedPreloader, getMore, setItems }) {
    const { user } = useContext(UserContext);
    const [itemsForm, setItemsForm] = useState(null)
    const [step, setStep] = useState(0)
    useEffect(() => {
        if (!isOpen) return
        setItemsForm(items)
    }, [items, isOpen])
    console.log({ items })

    // Helper function to determine minimal allowed amount for a particular item
    function getMinimalAllowedAmount(itemsForm, currentItem) {
        if (!itemsForm || itemsForm.length === 0) return 0;

        const { _id, options } = currentItem;

        // If there's only one item, minimal amount is 1
        if (itemsForm.length === 1) return 1;

        // If all other items have amount = 0
        const othersAllZero = itemsForm
            .filter(it => !(it._id === _id && deepEqual(it.options, options)))
            .every(it => Number(it.amount) === 0);

        if (othersAllZero) return 1;

        // Otherwise, minimal amount is 0
        return 0;
    }


    function handleMinus({ _id, options }) {
        console.log('minus', { _id, options });
        setItemsForm(prevValue => {
            if (!prevValue) return prevValue;

            // Determine minimal allowed value for this item
            const minimalValue = getMinimalAllowedAmount(prevValue, { _id, options });

            return prevValue.map((item) => {
                if (item._id === _id && deepEqual(item.options, options)) {
                    return {
                        ...item,
                        amount: `${Math.max(minimalValue, Number(item.amount) - 1)}`
                    };
                }
                return item;
            });
        });
    }

    function handlePlus({ _id, options }) {
        console.log('plus', { _id, options });
        setItemsForm(prevValue => {
            if (!prevValue) return prevValue;
            return prevValue.map((item) => {
                if (item._id === _id && deepEqual(item.options, options)) {
                    // Find the original max amount from the `items` prop
                    const originalItem = items.find(obj =>
                        obj._id === item._id && deepEqual(obj.options, item.options));
                    const maxAmount = Number(originalItem?.amount || item.amount);
                    return {
                        ...item,
                        amount: `${Math.min(maxAmount, Number(item.amount) + 1)}`
                    };
                }
                return item;
            });
        });
    }

    function handleToConfirmStep() {
        setStep(1)
    }

    const [isPreloaderVisible, setIsPreloaderVisible] = useState({
        submit: false,
    })
    function hendleSubmit() {
        setIsPreloaderVisible(prevValue => ({
            ...prevValue,
            submit: true,
        }))

        const itemsToUpdate = itemsForm.filter((item) => {
            const originalItem = items.find(obj =>
                obj._id === item._id &&
                deepEqual(obj.options, item.options)
            );
            return Number(item.amount) != Number(originalItem.amount)
        }).map((item) => ({
            amount: item.amount,
            _id: item._id,
            options: item.options,
            item_id: item.item_id,
        }))

        const shop_id = user.default_shop._id;

        mainApi.updateOrderItems({
            shop_id: shop_id,
            data: itemsToUpdate.map((item) => {
                return {
                    _id: item._id,
                    amount: `${item.amount}`,
                }
            }),
            order_id: order._id,
            payment_type: order.payment_type,
        })
            .then((res) => {
                console.log(res)
                setItems(prevItems => {
                    const newItems = [...prevItems];

                    itemsToUpdate.forEach(updatedItem => {
                        // Find index of the item with matching _id and options
                        const index = newItems.findIndex(it =>
                            it._id === updatedItem._id &&
                            deepEqual(it.options, updatedItem.options)
                        );

                        if (Number(updatedItem.amount) === 0) {
                            // If amount is 0, remove the item from the array
                            if (index !== -1) {
                                newItems.splice(index, 1);
                            }
                        } else {
                            // If amount > 0, update the item's amount
                            if (index !== -1) {
                                newItems[index] = {
                                    ...newItems[index],
                                    amount: `${updatedItem.amount}`,
                                };
                            } else {
                                // If for some reason item doesn't exist, you could add it 
                                // (only if that makes sense for your logic)
                                // newItems.push({...updatedItem, item: originalItemData});
                            }
                        }
                    });

                    return newItems;
                });
                onClosePopup()
            })
            .catch((err) => {

            })
            .finally(() => {
                setIsPreloaderVisible(prevValue => ({
                    ...prevValue,
                    submit: false,
                }))
            })
    }

    function onClosePopup() {
        if (isPreloaderVisible.submit) return
        handleClose()
        setTimeout(() => {
            setItemsForm(null)
            setStep(0)
            setIsPreloaderVisible(prevValue => ({
                ...prevValue,
                submit: false,
            }))
        }, 300);
    }
    return (
        <AdaptivePopup
            isOpen={isOpen}
            handleClose={onClosePopup}
            popupName={'editOrder'}
        >
            {
                step === 0 ?
                    order && items && items.length > 0 && itemsForm && itemsForm.length > 0 ?
                        ORDER_AVAILIBLE_FOR_EDIT_STATUSES.indexOf(order.status) >= 0 ?
                            <div className='edit-order-popup'>
                                <p className='edit-order-popup__title'>Изменение заказа</p>
                                <div className='edit-order-popup__items'>
                                    {itemsForm.map((item, i) => {
                                        const minimalValue = getMinimalAllowedAmount(itemsForm, item);
                                        const originalItem = items.find(obj =>
                                            obj._id === item._id &&
                                            deepEqual(obj.options, item.options)
                                        );
                                        const maxAmount = Number(originalItem?.amount || item.amount);

                                        return (
                                            <div className='edit-order-popup__item' key={`edit-order-popup__item${i}${item._id}`}>
                                                <div className='edit-order-popup__item-info'>
                                                    {item.item.files && item.item.files.length !== 0 ? (
                                                        <img
                                                            className="edit-order-popup__item-img"
                                                            src={item.item.files[0]?.urls?.orig}
                                                            alt=""
                                                        />
                                                    ) : (
                                                        <div className="edit-order-popup__item-img edit-order-popup__item-img-placeholder" />
                                                    )}
                                                    <div className='edit-order-popup__item-name-info'>
                                                        <h5 className="edit-order-popup__item-title">
                                                            {StringOrNothingFormater(item.item.name)}
                                                        </h5>
                                                        <p className="edit-order-popup__item-info-text">
                                                            UID: {item.item.unique_id}
                                                        </p>
                                                        <p className="edit-order-popup__item-info-text">
                                                            {item.price ? parsePrice(item.price, 'RUB') : ''}
                                                        </p>
                                                        {item.options && item.options.length > 0 &&
                                                            item.options.map((option, option_i) => (
                                                                <p
                                                                    className="edit-order-popup__item-info-text"
                                                                    key={`edit-order-popup__item-info_option_${option_i}`}
                                                                >
                                                                    {option.name}: {option.value}
                                                                </p>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                <div className='edit-order-popup__item-amount'>
                                                    <button
                                                        className={`edit-order-popup__item-amount-controller ${Number(item.amount) === minimalValue
                                                            ? 'edit-order-popup__item-amount-controller_inactive' : ''
                                                            }`}
                                                        type='button'
                                                        onClick={() => { handleMinus({ _id: item._id, options: item.options }) }}
                                                        disabled={Number(item.amount) === minimalValue}
                                                    >
                                                        -
                                                    </button>
                                                    <p className='edit-order-popup__item-amount-value'>{item.amount}</p>
                                                    <button
                                                        className={`edit-order-popup__item-amount-controller ${Number(item.amount) === maxAmount
                                                            ? 'edit-order-popup__item-amount-controller_inactive' : ''
                                                            }`}
                                                        type='button'
                                                        onClick={() => { handlePlus({ _id: item._id, options: item.options }) }}
                                                        disabled={Number(item.amount) === maxAmount}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    {isMoreBtnVisible ? (
                                        <button
                                            className={`order-page__more-btn ${isFeedPreloader ? "order-page__more-btn_disabled" : ""
                                                }`}
                                            onClick={getMore}
                                            type="button"
                                        >
                                            <p
                                                className={`order-page__more-btn-text ${!isFeedPreloader ? "order-page__more-btn-text_visible" : ""
                                                    }`}
                                            >
                                                Загрузить ещё
                                            </p>
                                            <span
                                                className={`order-page__btn-preloader ${isFeedPreloader ? "order-page__btn-preloader_visible" : ""
                                                    }`}
                                            >
                                                <MiniPreloader />
                                            </span>
                                        </button>
                                    ) : null}
                                </div>
                                <Button
                                    text={'Сохранить'}
                                    isInvalid={!(items && itemsForm && !deepEqual(items, itemsForm))}
                                    // isLoading={isPreloaderVisible}
                                    isContrastColor
                                    className={'edit-order-popup__btn'}
                                    onClick={() => {
                                        handleToConfirmStep()
                                    }}
                                />
                            </div>
                            :
                            <div className='edit-order-popup'>
                                <p className='edit-order-popup__title'>Изменение заказа недоступно</p>
                                <p className='edit-order-popup__subtitle'>Заказ можно изменить только, если он&nbsp;находится в&nbsp;статусах: <span dangerouslySetInnerHTML={{ __html: ORDER_AVAILIBLE_FOR_EDIT_STATUSES.map((item) => `&laquo;${parseStatus(item)}&raquo;`).join(', ') }}></span></p>
                            </div>
                        : null
                    : null
            }

            {
                step === 1 ?
                    <div className='edit-order-popup'>
                        <p className='edit-order-popup__title'>Подтвердите изменения</p>
                        <p className='edit-order-popup__subtitle'>Обратите внимание, что после изменения заказа вернуть назад позиции будет невозможно. Средства за&nbsp;удалённые товары будут автоматически возвращены покупателю</p>
                        <div className='edit-order-popup__items'>
                            {itemsForm.filter((item) => {
                                const originalItem = items.find(obj =>
                                    obj._id === item._id &&
                                    deepEqual(obj.options, item.options)
                                );
                                if (!originalItem) return true
                                return Number(item.amount) != Number(originalItem.amount)
                            }).map((item, i) => {
                                const originalItem = items.find(obj =>
                                    obj._id === item._id &&
                                    deepEqual(obj.options, item.options)
                                );

                                return (
                                    <div className='edit-order-popup__item' key={`edit-order-popup__item-to-delete${i}${item._id}`}>
                                        <div className='edit-order-popup__item-info'>
                                            {item.item.files && item.item.files.length !== 0 ? (
                                                <img
                                                    className="edit-order-popup__item-img"
                                                    src={item.item.files[0]?.urls?.orig}
                                                    alt=""
                                                />
                                            ) : (
                                                <div className="edit-order-popup__item-img edit-order-popup__item-img-placeholder" />
                                            )}
                                            <div className='edit-order-popup__item-name-info'>
                                                <h5 className="edit-order-popup__item-title">
                                                    {StringOrNothingFormater(item.item.name)}
                                                </h5>
                                                <p className="edit-order-popup__item-info-text">
                                                    UID: {item.item.unique_id}
                                                </p>
                                                {item.options && item.options.length > 0 &&
                                                    item.options.map((option, option_i) => (
                                                        <p
                                                            className="edit-order-popup__item-info-text"
                                                            key={`edit-order-popup__item-info_option_${option_i}`}
                                                        >
                                                            {option.name}: {option.value}
                                                        </p>
                                                    ))
                                                }
                                                <p className="edit-order-popup__item-info-amount">
                                                    <span className='edit-order-popup__item-info-text_line-throught'>{originalItem?.amount}</span> &rarr; <span className='edit-order-popup__item-info-text_bold'>{item.amount}</span>
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="edit-order-popup__btns">
                            <button
                                className="edit-order-popup__btn-submit edit-order-popup__btn-submit_agree"
                                type="button"
                                onClick={hendleSubmit}
                            >
                                {isPreloaderVisible.submit ? <MiniPreloader /> : 'Подтвердить'}
                            </button>
                            <button
                                className="edit-order-popup__btn-submit edit-order-popup__btn-submit_disagree"
                                type="button"
                                onClick={() => {
                                    setStep(0)
                                }}
                            >
                                Отменить
                            </button>
                        </div>
                    </div>
                    :
                    null
            }

        </AdaptivePopup>
    );
}

export default EditOrderPopup