import { useState } from 'react';
import { EditIcon } from '../../../../../../../../assets/icons/category/category';
import { DEFAULT_BANNER_SIZE, DEFAULT_MAX_IMG_SIZE } from '../../../../../../../../assets/utils/constants';
import ImagesInput from '../../../../../../ImagesInput/ImagesInput';
import PlatformInput from '../../../../../../PlatformInput/PlatformInput';
import EditProductsPopup from './EditProductsPopup/EditProductsPopup';
import './ShowCase.css';

function ShowCase({ item, handleChange, title }) {
    const [popups, setPopups] = useState({
        editProducts: false,
    })

    function handleCloseEditProductsPopup() {
        setPopups(prevValue => ({
            ...prevValue,
            editProducts: false,
        }))
    }
    console.log({ item })
    return (
        <div className='show-case'>
            {item.block.input_data.title && item.block.input_data.title.editable ?
                <PlatformInput
                    label={'Заголовок'}
                    value={item.data[0].title}
                    handleChange={(e) => {
                        handleChange({
                            target: {
                                index: 0,
                                value: e.target.value,
                                item: item,
                                name: 'show-case',
                                subname: 'title',
                            }
                        })
                    }}
                    isTextArea={true}
                    minRows={1}
                    maxRows={5}
                    type={'text'}
                    name={''}
                />
                : null}
            {item.block.input_data.text && item.block.input_data.text.editable ?
                <PlatformInput
                    label={'Текст'}
                    value={item.data[0].text}
                    handleChange={(e) => {
                        handleChange({
                            target: {
                                index: 0,
                                value: e.target.value,
                                item: item,
                                name: 'show-case',
                                subname: 'text',
                            }
                        })
                    }}
                    isTextArea={true}
                    minRows={1}
                    maxRows={5}
                    type={'text'}
                    name={''}
                />
                : null}

            {item.block.input_data.image && item.block.input_data.image.editable ?
                <ImagesInput
                    name={"image"}
                    label={"Изображение"}
                    hint={`(${DEFAULT_BANNER_SIZE}) Не более ${DEFAULT_MAX_IMG_SIZE} Мб`}
                    addBtnText={"Добавить..."}
                    isMultiple={false}
                    images={item.data[0].image}
                    additionalKey={`${item._id}_show-case`}
                    onRemove={(e) => {
                        handleChange({
                            target: {
                                index: 0,
                                value: e.target.index,
                                item: item,
                                name: 'show-case',
                                subname: 'image-delete',
                            }
                        })
                    }}
                    onAdd={(e) => {
                        handleChange({
                            target: {
                                index: 0,
                                ...e.target,
                                item: item,
                                name: 'show-case',
                                subname: 'image',
                                files: e.target.files,
                            }
                        })
                    }}
                />
                : null}

            {item.block.input_data.link && item.block.input_data.link.editable ?
                <PlatformInput
                    label={'Ссылка'}
                    value={item.data[0].link}
                    handleChange={(e) => {
                        handleChange({
                            target: {
                                index: 0,
                                value: e.target.value,
                                item: item,
                                name: 'show-case',
                                subname: 'link',
                            }
                        })
                    }}
                    type={'text'}
                    name={''}
                />
                : null}

            {item.block.input_data.products && item.block.input_data.products.editable ?
                <div className='show-case__products'>
                    <EditProductsPopup
                        isOpen={popups.editProducts}
                        handleClose={handleCloseEditProductsPopup}
                        items={item.data[0].products}
                        blockTitle={title}
                        block={item}
                        handleChangeBlock={handleChange}

                    />
                    <p className='show-case__products-title'>Товары</p>
                    {item.data[0].products && item.data[0].products.length > 0 ?
                        <div className='show-case__products-items'>
                            {item.data[0].products.map((product, i) => (
                                <div className='show-case__products-item'>
                                    {product.files && product.files.length !== 0 ? (
                                        <img
                                            className="show-case__products-item-img"
                                            src={product.files[0]?.urls?.orig}
                                            alt=""
                                        />
                                    ) : (
                                        <div className="show-case__products-item-img show-case__products-item-img-placeholder" />
                                    )}
                                </div>
                            ))}
                            {Array.from({ length: item.block.input_data.products.limit - item.data[0].products.length }).map((obj, i) => (
                                <div className='show-case__products-item'>
                                    {i + item.data[0].products.length + 1}
                                </div>
                            ))}
                        </div>
                        :
                        <p className='how-case__products-random'>{item.block.input_data.products.limit} случайних товаров</p>
                    }
                    <button className='promo-banner__btn' type='button' onClick={() => {
                        setPopups(prevValue => ({
                            ...prevValue,
                            editProducts: true,
                        }))
                    }}>
                        <EditIcon
                            mainClassName={'promo-banner__btn-icon'}
                            fillClassName={'promo-banner__btn-icon-fill'}
                        />
                        Настроить отображение
                    </button>
                </div>
                : null}


        </div>
    );
}

export default ShowCase