import { useContext, useEffect, useState } from 'react';
import './EditProductsPopup.css';
import AdaptivePopup from '../../../../../../../../AdaptivePopup/AdaptivePopup';
import Button from '../../../../../../../../Button/Button';
import { StringOrNothingFormater, getMinPrice, parsePrice } from '../../../../../../../../../assets/utils/utils';
import SearchProductPopup from '../SearchProductPopup/SearchProductPopup';
import { CrossIcon } from '../../../../../../../../../assets/icons/controls/controls';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function EditProductsPopup({ isOpen, handleClose, items, blockTitle, block, handleChangeBlock }) {
    const [popups, setPopups] = useState({
        searchAddProduct: false,
    });

    // We'll keep a local copy of the items so we can reorder them easily
    const [localItems, setLocalItems] = useState(items || []);

    useEffect(() => {
        setLocalItems(items || [])
    }, [items]);

    function handleCloseSearchAddProductPopup() {
        setPopups(prevValue => ({
            ...prevValue,
            searchAddProduct: false,
        }));
    }

    function handleDeleteProduct(item) {
        handleChangeBlock({
            target: {
                index: 0,
                value: item,
                item: block,
                name: 'show-case',
                subname: 'delete-product',
            }
        });
    }

    function onClosePopup() {
        handleClose();
        setTimeout(() => {
            // Do any cleanup if needed
        }, 300);
    }

    // Reordering function
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    // Handle drag end event
    const onDragEnd = (result) => {
        const { destination, source } = result;
        // If dropped outside the list or no change in position
        if (!destination || destination.index === source.index) {
            return;
        }

        const reorderedItems = reorder(localItems, source.index, destination.index);
        setLocalItems(reorderedItems);

        // Optionally, inform parent of the new order
        handleChangeBlock({
            target: {
                index: 0,
                value: reorderedItems, // or full objects if needed
                item: block,
                name: 'show-case',
                subname: 'reorder-products',
            }
        });
    };

    return (
        <>
            <AdaptivePopup
                isOpen={isOpen}
                handleClose={onClosePopup}
                popupName={'editProducts'}
            >

                <div className='edit-products-popup'>
                    <div className='edit-products-popup__heading'>
                        <p className='edit-products-popup__title'>Настройка отображения <br />{blockTitle}</p>
                        <button className='edit-products-popup__close' type='button' >
                            <CrossIcon mainClassName={'edit-products-popup__close-icon'} strokeClassName={'edit-products-popup__close-icon-stroke'} />
                        </button>
                    </div>

                    <div className='edit-products-popup__items'>
                        {localItems && localItems.length > 0 ? (
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="products-droppable">
                                    {(provided) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {localItems.map((item, i) => {
                                                const price = item.options && item.options.length === 1
                                                    ? getMinPrice(item.options[0].values)
                                                    : item.price_data.price;

                                                return (
                                                    <Draggable
                                                        key={item._id}
                                                        draggableId={item._id}
                                                        index={i}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                                className={`edit-products-popup__item ${snapshot.isDragging ? 'edit-products-popup__item_dragging' : ''}`}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}

                                                                style={{
                                                                    ...provided.draggableProps.style,
                                                                    left: "auto !important",
                                                                    top: "auto !important",
                                                                }}>

                                                                <div className='edit-products-popup__item-draggable'>
                                                                    <div className='edit-products-popup__item-draggable-icon'></div>
                                                                    <div className='edit-products-popup__item-draggable-icon'></div>
                                                                    <div className='edit-products-popup__item-draggable-icon'></div>
                                                                    <div className='edit-products-popup__item-draggable-icon'></div>
                                                                    <div className='edit-products-popup__item-draggable-icon'></div>
                                                                    <div className='edit-products-popup__item-draggable-icon'></div>
                                                                </div>
                                                                <div className='edit-products-popup__item-info'>
                                                                    {item.files && item.files.length !== 0 ? (
                                                                        <img
                                                                            className="edit-products-popup__item-img"
                                                                            src={item.files[0]?.urls?.orig}
                                                                            alt=""
                                                                        />
                                                                    ) : (
                                                                        <div className="edit-products-popup__item-img edit-products-popup__item-img-placeholder" />
                                                                    )}
                                                                    <div className='edit-products-popup__item-name-info'>
                                                                        <h5 className="search-product-popup__item-title">
                                                                            {StringOrNothingFormater(item.name)}
                                                                        </h5>
                                                                        <p className="search-product-popup__item-info-text">
                                                                            {item.options && item.options.length === 1 ? 'от ' : ''}{price ? parsePrice(price, 'RUB') : ''}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <button
                                                                    className="search-product-popup__item-delete"
                                                                    type='button'
                                                                    onClick={(e) => {
                                                                        e.preventDefault()
                                                                        handleDeleteProduct(item)
                                                                    }}
                                                                >
                                                                    <CrossIcon
                                                                        mainClassName={'search-product-popup__item-delete-icon'}
                                                                        strokeClassName={'search-product-popup__item-delete-icon-stroke'}
                                                                    />
                                                                </button>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        ) : (
                            <p className='edit-products-popup__no-item'>Товары для отображения не выбраны</p>
                        )}
                    </div>

                    <Button
                        text={'Добавить товар'}
                        isInvalid={!(block.data[0].products ? block.data[0].products.length < block.block.input_data.products.limit : true)}
                        isContrastColor
                        className={'edit-products-popup__btn'}
                        onClick={() => {
                            setPopups(prevValue => ({
                                ...prevValue,
                                searchAddProduct: true,
                            }))
                        }}
                    />
                </div>
            </AdaptivePopup>
            <SearchProductPopup
                isOpen={popups.searchAddProduct}
                handleClose={handleCloseSearchAddProductPopup}
                block={block}
                handleChangeBlock={handleChangeBlock}
            />
        </>

    );
}

export default EditProductsPopup;
