import { MAIN_PUBLIC_URL } from "../utils/constants";

class PublicApi {
    constructor({ baseUrl }) {
        this._BASE_URL = baseUrl;
    }

    _checkResponse(res) {
        if (res.ok) {
            return res.json();
        } else {
            const statusCode = res.status;
            return res.json().then((res) => {
                return Promise.reject({
                    statusCode: statusCode,
                    message: res.msg,
                    detail: res.detail,
                });
            });
        }
    }

    searchAddress({ query }) {
        let params = {};
        if (query) params.query = query;

        return fetch(
            `${MAIN_PUBLIC_URL}/delivery/search-address?` +
            new URLSearchParams({ data: JSON.stringify(params) }),
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        ).then(this._checkResponse);
    }

    getCoordinates({ address }) {
        let params = {};
        if (address) params.address = address;

        return fetch(
            `${MAIN_PUBLIC_URL}/delivery/get-coordinates?` +
            new URLSearchParams({ data: JSON.stringify(params) }),
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        ).then(this._checkResponse);
    }

    getSearch({ shop_id, limit, category_id, page, scroll_id, string }) {
        let params = {};
        if (shop_id) params.shop_id = shop_id;
        params.string = string
        if (limit) params.limit = limit
        if (category_id) params.category_id = category_id
        if (page) params.page = page
        if (scroll_id) params.scroll_id = scroll_id
        return fetch(
            `${MAIN_PUBLIC_URL}/search/query?` +
            new URLSearchParams({ data: JSON.stringify(params) }),
            {
                method: "GET",
                // credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        ).then(this._checkResponse);
    }

    getExactProduct({ shop_id, _id }) {
        let params = {};
        if (shop_id) params.shop_id = shop_id;
        if (_id) params._id = _id;
    
        return fetch(
          `${MAIN_PUBLIC_URL}/products/get-by-id?` +
          new URLSearchParams({ data: JSON.stringify(params) }),
          {
            method: "GET",
            // credentials: "include",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        ).then(this._checkResponse);
      }

}

const publicApi = new PublicApi({
    baseUrl: MAIN_PUBLIC_URL,
});

export default publicApi;
