import { useContext, useEffect, useState } from "react";
import MiniPreloader from "../../../../MiniPreloader/MiniPreloader";
import ProductsList from "../../../ProductsList/ProductsList";
import "./OrderItems.css";
import { UserContext } from "../../../../../assets/contexts/userContext";
import mainApi from "../../../../../assets/api/MainApi";
import useAutoDismissError from "../../../../../assets/hooks/useAutoDismissError";
import { parseApiError } from "../../../../../assets/utils/utils";
import ErrorMessage from "../../../../ErrorMessage/ErrorMessage";
import { EditIcon } from "../../../../../assets/icons/category/category";
import EditOrderPopup from "./EditOrderPopup/EditOrderPopup";
import { ORDER_AVAILIBLE_FOR_EDIT_STATUSES } from "../../../../../assets/utils/constants";


function OrderItems({ order }) {
  const { user } = useContext(UserContext);
  const [data, setData] = useState([]);
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(false);
  const [isFeedPreloader, setIsFeedPreloader] = useState(false);
  const [isMoreBtnVisible, setIsMoreBtnVisible] = useState(false);
  const [error, showError] = useAutoDismissError();
  const [popups, setPopups] = useState({
    editOrder: false,
  })

  useEffect(() => {
    if (!user || !order) return;

    setIsPreloaderVisible(true);
    getOrderItems({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, order]);

  function getOrderItems({ last_id }) {
    const shop_id = user.default_shop._id;
    mainApi
      .getOrderItems({ shop_id, _id: order._id })
      .then((res) => {
        const orders = res.data;
        setData(last_id ? (prevArr) => prevArr.concat(orders) : orders);
        setIsMoreBtnVisible(res.is_more);
      })
      .catch((err) => {
        if (err.statusCode === 403) {
          setData([]);
          setIsMoreBtnVisible(false)
        }
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloaderVisible(false);
        setIsFeedPreloader(false);
      });
  }

  function getMore() {
    const last_id = data[data.length - 1]._id;
    setIsFeedPreloader(true);
    getOrderItems({ last_id });
  }

  console.log({ order })

  function hadnleOpenEdit() {
    setPopups(prevValue => ({
      ...prevValue,
      editOrder: true,
    }))
  }

  function handleCloseEditOrder() {
    setPopups(prevValue => ({
      ...prevValue,
      editOrder: false,
    }))
  }

  return !isPreloaderVisible ? (
    <div className="order-items">
      <EditOrderPopup
        isOpen={popups.editOrder}
        handleClose={handleCloseEditOrder}
        order={order}
        items={data}
        isMoreBtnVisible={isMoreBtnVisible}
        isFeedPreloader={isFeedPreloader}
        getMore={getMore}
        setItems={setData}
      />
      <div className="order-items__heading">
        {order && ORDER_AVAILIBLE_FOR_EDIT_STATUSES.indexOf(order.status) >= 0 ?
          <button className="order-items__edit" type="button" onClick={hadnleOpenEdit}>
            Изменить заказ
            <EditIcon mainClassName={'order-items__edit-icon'} fillClassName={'order-items__edit-icon-fill'} />
          </button>
          :
          null
        }
      </div>
      {data?.length > 0 ? (
        <ProductsList
          className={""}
          products={order.discount ? data.map((order_item) => ({
            ...order_item,
            price: Number(order_item.price) - (Number(order_item.price) / 100 * Number(order.discount)),
          })) : data}
          name={"order-items"}
          is_order_item={true}
        />
      ) : error ? (
        <ErrorMessage error={error} />
      ) : null}

      {isMoreBtnVisible ? (
        <button
          className={`order-page__more-btn ${isFeedPreloader ? "order-page__more-btn_disabled" : ""
            }`}
          onClick={getMore}
          type="button"
        >
          <p
            className={`order-page__more-btn-text ${!isFeedPreloader ? "order-page__more-btn-text_visible" : ""
              }`}
          >
            Загрузить ещё
          </p>
          <span
            className={`order-page__btn-preloader ${isFeedPreloader ? "order-page__btn-preloader_visible" : ""
              }`}
          >
            <MiniPreloader />
          </span>
        </button>
      ) : null}
    </div>
  ) : (
    <div className="prev-orders__preloader">
      <MiniPreloader />
    </div>
  );
}

export default OrderItems;
