import { useContext, useEffect, useState } from 'react';

import './ExportPopup.css';
import AdaptivePopup from '../../../AdaptivePopup/AdaptivePopup';
import MiniPreloader from '../../../MiniPreloader/MiniPreloader';
import Button from '../../../Button/Button';



function ExportPopup({ isOpen, handleClose, isPreloaderVisible, file }) {

    function handleDownload() {
        if (!file) return;

        // Extract the URL from the file object
        const url = file.urls.orig;
        // Derive a filename (optional). You might parse from URL or use a known file name.
        const filename = url.split('/').pop() || 'download';

        // Create a temporary anchor element
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;

        // Append it to the body to make it part of the DOM
        document.body.appendChild(a);

        // Programmatically trigger the click event on the anchor
        a.click();

        // Cleanup: remove the anchor from the DOM
        document.body.removeChild(a);
    }
    return (
        <AdaptivePopup
            isOpen={isOpen}
            handleClose={handleClose}
            popupName={'export'}
        >
            <div className='export-popup'>
                <p className='export-popup__title'>{isPreloaderVisible ? 'Подготовка файла' : 'Файл готов!'}</p>
                <p className='export-popup__subtitle' dangerouslySetInnerHTML={{
                    __html:
                        isPreloaderVisible ?
                            'Пожалуйста, подождите. Идет формирование файла&nbsp;для&nbsp;экспорта'
                            :
                            'Нажмите «Скачать», чтобы получить подготовленный файл'
                }}></p>
                <Button
                    text={'Скачать'}
                    isLoading={isPreloaderVisible}
                    isContrastColor
                    className={'export-popup__btn'}
                    onClick={() => {
                        handleDownload()
                    }}
                />
            </div>
        </AdaptivePopup>
    );
}

export default ExportPopup