import { Route, Routes, useNavigate } from "react-router-dom";
import PageHeading from "../../../PageHeading/PageHeading";
import { useContext, useEffect, useState } from "react";
import useWindowSize from "../../../../../assets/hooks/useWindowSize";
import ItemForm from "../../../ItemForm/ItemForm";
import ProductsList from "../../../ProductsList/ProductsList";
import { createAction, getRandomId, hasPermission, parseApiError, parsePermissions } from "../../../../../assets/utils/utils";
import ProductsProductForm from "./ProductsProductForm/ProductsProductForm";
import ProductFormPlaceholder from "../../../ProductForm/ProductFormPlaceholder/ProductFormPlaceholder";
import {
  ARTUCUL_EN,
  ARTUCUL_RU,
  CATALOG_MAIN_LINK,
  CATALOG_PRODUCTS_ITEM_LINK,
  CATALOG_PRODUCTS_LINK,
  DRAFTS_LIMIT,
  ERROR_PHOTO_MAX_LENGTH,
  ERROR_PRICE_DATA_MIN_VALUE,
  ERROR_REQUIRED_FIELD,
  FILE_TYPE_XLSX,
  PLATFORM_MAIN_LINK,
  PRODUCT_FORM_TYPES,
  PRODUCT_FORM_TYPE_NUMBER,
  PRODUCT_FORM_TYPE_TEXT,
  SELECTION_ALL_TYPE,
  SELECTION_EXCLUDE_TYPE,
  SELECTION_INCLUDE_TYPE,
} from "../../../../../assets/utils/constants";
import "./ProductsMain.css";
import mainApi from "../../../../../assets/api/MainApi";
import { UserContext } from "../../../../../assets/contexts/userContext";
import useAutoDismissError from "../../../../../assets/hooks/useAutoDismissError";
import MiniPreloader from "../../../../MiniPreloader/MiniPreloader";
import ErrorMessage from "../../../../ErrorMessage/ErrorMessage";
import SubmitActionPopup from "../../../SubmitActionPopup/SubmitActionPopup";
import { PERMISSIONS, RBAC_DRAFTS, RBAC_PUBLISHED } from "../../../../../assets/utils/permissions_rbac";
import ExportPopup from "../../ExportPopup/ExportPopup";

const initialValuesValidity = {
  unique_sku: {
    errorMessage: "",
    validState: false,
  },
  title: {
    errorMessage: "",
    validState: false,
  },
  price: {
    errorMessage: "",
    validState: false,
  },
  discount: {
    errorMessage: "",
    validState: true,
  },
  amount: {
    errorMessage: "",
    validState: true,
  },
  photo: {
    errorMessage: "",
    validState: true,
  },
  categories: {
    errorMessage: "",
    validState: false,
  },
  options: [],
  parameters: [],
};

function ProductsMain() {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(undefined);
  const [initialProduct, setInitialProduct] = useState(undefined);
  const [isMoreBtnVisible, setIsMoreBtnVisible] = useState(false);
  const [error, showError] = useAutoDismissError();
  const [productValidity, setProductValidity] = useState(initialValuesValidity);
  const [initialValidity, setInitialValidity] = useState(initialValuesValidity);
  const [isPreloaderVisible, setIsPreloaderVisible] = useState({
    main: true,
    item: false,
    feed: false,
    save: false,
    delete: false,
    selected: false,
  });
  const [isSubmitPopupOpen, setSubmitPopupOpen] = useState(false);
  const [deleteError, setDeleteError] = useState("");
  const [selectedProducts, setSelectedProducts] = useState({
    all: false,
    exclude: [],
    include: [],
  });
  const [clickedSide, setClickedSide] = useState("left");

  const hasViewDraftPermission = hasPermission(parsePermissions(user), [RBAC_DRAFTS[PERMISSIONS.VIEW]])
  const hasViewPublishedPermission = hasPermission(parsePermissions(user), [RBAC_PUBLISHED[PERMISSIONS.VIEW]])
  const hasAddPermission = hasPermission(parsePermissions(user), [RBAC_DRAFTS[PERMISSIONS.ADD]])
  const hasMoveToDraftPermission = hasPermission(parsePermissions(user), [RBAC_PUBLISHED[PERMISSIONS.MOVE_TO_DRAFTS]])
  const hasDeletePermission = hasPermission(parsePermissions(user), [RBAC_PUBLISHED[PERMISSIONS.DELETE]])
  const hasEditPermission = hasPermission(parsePermissions(user), [RBAC_PUBLISHED[PERMISSIONS.EDIT]])

  const [popups, setPopups] = useState({
    export: false,
  })

  const [exportFile, setExportFile] = useState(null)

  function handleExportProducts() {
    setIsPreloaderVisible(prevValue => ({
      ...prevValue,
      export: true,
    }))
    setPopups(prevValue => ({
      ...prevValue,
      export: true,
    }))
    const shop_id = user.default_shop._id;
    mainApi.exportProducts({ shop_id: shop_id, file_type: FILE_TYPE_XLSX })
      .then((res) => {
        console.log(res)
        setExportFile(res)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setIsPreloaderVisible(prevValue => ({
          ...prevValue,
          export: false,
        }))
      })
  }

  function handleExportClose() {
    setPopups(prevValue => ({
      ...prevValue,
      export: false,
    }))
    setTimeout(() => {

    }, 300);
  }


  let actions_left = []
  let actions_right = []
  function addActions() {

    if (hasViewDraftPermission && hasViewPublishedPermission) {
      actions_left.push(createAction("Экспорт", handleExportProducts, {
        isPreloaderVisible: isPreloaderVisible.export,
      }));
    }
    // Ensure actions are only added if conditions are met to prevent unnecessary IDs generation
    if (!product) return;

    // Adjusted actions creation with stable IDs
    if (hasDeletePermission) {
      const deleteAction = createAction("Удалить товар", toggleSubmitPopup, {
        inactive: isPreloaderVisible.delete || isPreloaderVisible.save,
        isPreloaderVisible: isPreloaderVisible.delete,
      });

      if (selectedProducts.include.length === 1) actions_left.push(deleteAction);
      actions_right.push(deleteAction);
    }

    if ((selectedProducts.all || selectedProducts.include.length > 0) && hasMoveToDraftPermission) {
      actions_left.push(createAction("В черновики", handleMoveToDraftsSelected, {
        inactive: isPreloaderVisible.selected,
        isPreloaderVisible: isPreloaderVisible.selected,
        isMainAction: true,
      }));
    }


    if (hasEditPermission) {
      actions_right.push(createAction("Отменить все изменения", handleRestoreProduct, {
        inactive: isPreloaderVisible.delete || isPreloaderVisible.save,
      }));
      function isSaveActionInactive() {
        const isValidProduct = productValidity.unique_sku?.validState &&
          (product.options.length === 1 ? true : (productValidity.amount?.validState && productValidity.price?.validState)) &&
          productValidity.title?.validState &&
          productValidity.discount?.validState &&
          productValidity.photo?.validState &&
          productValidity.options.every(option =>
            option.title.validState &&
            option.values.every(value =>
              product.options.length === 1
                ? value.amount.validState && value.value.validState && value.price.validState
                : value.value.validState
            )
          ) &&
          productValidity.parameters.every(param =>
            param.title.validState && param.value.validState
          );

        return !isValidProduct || isPreloaderVisible.delete || isPreloaderVisible.save;
      }

      actions_right.push(createAction("Сохранить", editItemPublished, {
        isMainAction: true,
        inactive: isSaveActionInactive(),
        isPreloaderVisible: isPreloaderVisible.save,
      }));
    }
  }

  // Execute to populate actions
  addActions();


  useEffect(() => {
    if (!user) return;
    setIsPreloaderVisible((prevVal) => ({ ...prevVal, main: true }));
    getAllProducts({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  function getAllProducts({ last_id }) {
    const shop_id = user.default_shop._id;
    mainApi
      .getAllItemsPublished({ shop_id, limit: DRAFTS_LIMIT, last_id })
      .then((res) => {
        const data = res.data;
        setProducts(last_id ? (prevArr) => prevArr.concat(data) : data);
        setIsMoreBtnVisible(res.is_more);
      })
      .catch((err) => {
        if (err.statusCode === 403) {
          setProducts([])
          setIsMoreBtnVisible(false)
        }
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloaderVisible((prevVal) => ({
          ...prevVal,
          main: false,
          feed: false,
        }));
      });
  }

  function getMore() {
    const last_id = products[products.length - 1]._id;
    setIsPreloaderVisible((prevVal) => ({ ...prevVal, feed: true }));
    getAllProducts({ last_id });
  }

  function handleSelect({ type, item }) {
    switch (type) {
      case "all": {
        setSelectedProducts((prevValue) => {
          if (prevValue.exclude.length > 0) {
            return {
              all: true,
              exclude: [],
              include: [],
            };
          } else {
            if (!prevValue.all) {
              return {
                all: true,
                exclude: [],
                include: [],
              };
            } else {
              return {
                all: false,
                exclude: [],
                include: [],
              };
            }
          }
        });
        break;
      }

      case "item": {
        setSelectedProducts((prevValue) => {
          if (prevValue.all) {
            return {
              all: true,
              exclude:
                prevValue.exclude.indexOf(item._id) >= 0
                  ? prevValue.exclude.filter((item2) => item2 !== item._id)
                  : prevValue.exclude.concat([item._id]),
              include: [],
            };
          } else {
            return {
              all: false,
              exclude: [],
              include:
                prevValue.include.indexOf(item._id) >= 0
                  ? prevValue.include.filter((item2) => item2 !== item._id)
                  : prevValue.include.concat([item._id]),
            };
          }
        });
        break;
      }

      default:
        break;
    }
  }

  function handleMoveToDraftsSelected() {
    const { all, exclude, include } = selectedProducts;
    const type = all
      ? exclude && exclude.length > 0
        ? SELECTION_EXCLUDE_TYPE
        : SELECTION_ALL_TYPE
      : SELECTION_INCLUDE_TYPE;
    const shop_id = user.default_shop._id;
    setIsPreloaderVisible((prevVal) => ({ ...prevVal, selected: true }));
    mainApi
      .moveToDrafts({
        shop_id,
        data:
          type === SELECTION_ALL_TYPE
            ? null
            : type === SELECTION_EXCLUDE_TYPE
              ? exclude
              : include,
        type,
      })
      .then(() => {
        setProducts((prevVal) =>
          prevVal.filter((item) => {
            if (type === SELECTION_ALL_TYPE) return false;
            if (type === SELECTION_EXCLUDE_TYPE) {
              return !exclude.includes(item._id);
            } else {
              return !include.includes(item._id);
            }
          })
        );
        setSelectedProducts({
          all: false,
          exclude: [],
          include: [],
        });
        window.location.reload();
      })
      .catch((err) => {
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloaderVisible((prevVal) => ({ ...prevVal, selected: false }));
      });
  }

  function handleRestoreProduct() {
    setProduct(initialProduct);
    setProductValidity(initialValidity);
  }

  function getItemPublishedById({ _id }) {
    if (!user) return;

    const shop_id = user.default_shop._id;
    setIsPreloaderVisible((prevVal) => ({ ...prevVal, item: true }));
    mainApi
      .getItemPublishedById({ shop_id, _id })
      .then((res) => {
        const selectedProductValidity = {
          unique_sku: {
            errorMessage: Boolean(res.unique_id) ? "" : ERROR_REQUIRED_FIELD,
            validState: Boolean(res.unique_id),
          },
          title: {
            errorMessage: Boolean(res.name) ? "" : ERROR_REQUIRED_FIELD,
            validState: Boolean(res.name),
          },
          price: {
            errorMessage: Boolean(res.price_data.price)
              ? ""
              : ERROR_REQUIRED_FIELD,
            validState: Boolean(res.price_data.price),
          },
          discount: {
            errorMessage: Boolean(res.price_data.discount)
              ? Number(res.price_data.discount) > 0
                ? ""
                : ERROR_PRICE_DATA_MIN_VALUE
              : "",
            validState: Boolean(res.price_data.discount)
              ? Number(res.price_data.discount) > 0
              : true,
          },
          amount: {
            errorMessage:
              Boolean(res.price_data.in_stock)
                ? ""
                : ERROR_REQUIRED_FIELD,
            validState:
              Boolean(res.price_data.in_stock),
          },
          photo: {
            errorMessage:
              res.files.length > 10
                ? ERROR_PHOTO_MAX_LENGTH
                : "",
            validState: res.files.length <= 10,
          },
          categories: {
            errorMessage:
              res.categories.length !== 0 ? "" : ERROR_REQUIRED_FIELD,
            validState: res.categories.length !== 0,
          },
          options:
            res.options?.length > 0
              ? res.options.map((item) => {
                return {
                  title: {
                    errorMessage: Boolean(item.name)
                      ? ""
                      : ERROR_REQUIRED_FIELD,
                    validState: Boolean(item.name),
                  },
                  values:
                    item.values.map((value) => {
                      return {
                        value: {
                          errorMessage: Boolean(value.value)
                            ? ""
                            : ERROR_REQUIRED_FIELD,
                          validState: Boolean(value.value),
                        },
                        amount: {
                          errorMessage:
                            Boolean(`${value.in_stock}`) || res.options.length !== 1
                              ? ""
                              : ERROR_REQUIRED_FIELD,
                          validState:
                            Boolean(`${value.in_stock}`) || res.options.length !== 1,
                        },
                        price: {
                          errorMessage:
                            Boolean(value.price) || res.options.length !== 1
                              ? ""
                              : ERROR_REQUIRED_FIELD,
                          validState:
                            Boolean(value.price) || res.options.length !== 1,
                        },
                      };
                    }),
                };
              })
              : [],
          parameters:
            res.parameters?.length > 0
              ? res.parameters
                .filter(
                  (item) =>
                    item.name.toLowerCase() !== ARTUCUL_EN &&
                    item.name.toLowerCase() !== ARTUCUL_RU
                )
                .map((item) => {
                  return {
                    title: {
                      errorMessage: Boolean(item.name)
                        ? ""
                        : ERROR_REQUIRED_FIELD,
                      validState: Boolean(item.name),
                    },
                    value: {
                      errorMessage:
                        item.value !== "" ? "" : ERROR_REQUIRED_FIELD,
                      validState: item.value !== "",
                    },
                  };
                })
              : [],
        };

        const selectedProduct = {
          _id: res._id,
          unique_sku: res.unique_id ? res.unique_id : "",
          title: res.name ? res.name : "",
          description: res.description ? res.description : "",
          price: res.price_data?.price ? res.price_data.price : "",
          amount: res.price_data?.in_stock ? res.price_data.in_stock : "",
          discount: res.price_data?.discount ? res.price_data.discount : "",
          visibility: res.is_visible !== undefined ? res.is_visible : true,
          photo: res.files ? res.files : [],
          categories: res.categories ? res.categories : [],
          promo_groups: res.promo_groups ? res.promo_groups : [],
          options:
            res.options?.length > 0
              ? res.options.map((item, i) => {
                const option_id = getRandomId();
                selectedProductValidity.options[i]._id = option_id;
                return {
                  title: item.name,
                  _id: option_id,
                  values: item.values.map((value, value_i) => {
                    const value_id = getRandomId();
                    selectedProductValidity.options[i].values[value_i]._id =
                      value_id;
                    return {
                      value: value.value,
                      amount: value.in_stock,
                      price: value.price,
                      _id: value_id,
                    };
                  }),
                };
              })
              : [],
          parameters:
            res.parameters?.length > 0
              ? res.parameters
                .filter(
                  (item) =>
                    item.name.toLowerCase() !== ARTUCUL_EN &&
                    item.name.toLowerCase() !== ARTUCUL_RU
                )
                .map((item, i) => {
                  const parameter_id = getRandomId();
                  selectedProductValidity.parameters[i]._id = parameter_id;
                  return {
                    title: item.name,
                    type: PRODUCT_FORM_TYPES.find(
                      (type) => type.type === item.type
                    ),
                    value: item.value,
                    _id: parameter_id,
                  };
                })
              : [],
          article:
            res.parameters?.length > 0
              ? res.parameters.find(
                (item) =>
                  item.name.toLowerCase() === ARTUCUL_EN ||
                  item.name.toLowerCase() === ARTUCUL_RU
              )?.value
              : "",
        };
        // setClickedSide("right");
        setProduct(selectedProduct);
        setInitialProduct(selectedProduct);
        setProductValidity(selectedProductValidity);
        setInitialValidity(selectedProductValidity);
      })
      .catch((err) => {
        if (err.statusCode === 403) {
          setProduct(undefined)
          setInitialProduct(undefined)
          setProductValidity(initialValuesValidity)
          setInitialValidity(initialValuesValidity);

        }
        switch (err.statusCode) {
          case 404:
            navigate(
              `${PLATFORM_MAIN_LINK}/${CATALOG_MAIN_LINK}/${CATALOG_PRODUCTS_LINK}`
            );
            break;

          default:
            showError(parseApiError(err));
            break;
        }
      })
      .finally(() => {
        setIsPreloaderVisible((prevVal) => ({ ...prevVal, item: false }));
      });
  }

  function editItemPublished() {
    if (!user) return;

    const shop_id = user.default_shop._id;
    setIsPreloaderVisible((prevVal) => ({ ...prevVal, save: true }));
    mainApi
      .editItemPublished({
        shop_id,
        files:
          product.photo.length > 0 ? product.photo.map((img) => img._id) : null,
        _id: product._id,
        unique_id: product.unique_sku,
        name: product.title,
        description: product.description ? product.description : null,
        price_data: {
          price: product.options.length !== 1
            ? product.price
              ? product.price
              : null
            : null,
          discount: product.discount ? Number(product.discount) : null,
          in_stock:
            product.options.length !== 1
              ? product.amount
                ? Number(product.amount)
                : null
              : null,
        },
        categories:
          product.categories.length > 0
            ? product.categories.map((item) => item._id)
            : [],
        promo_groups:
          product.promo_groups.length > 0 ? product.promo_groups : null,
        is_visible: product.visibility,
        options:
          product.options.length > 0
            ? product.options.map((item) => {
              return {
                name: item.title,
                values: item.values.map((value) => {
                  return {
                    value: value.value,
                    price_raise: value.price_raise ? value.price_raise : null,
                    in_stock:
                      product.options.length === 1
                        ? Number(value.amount)
                        : null,
                    price:
                      product.options.length === 1
                        ? value.price
                        : null,
                  };
                }),
              };
            })
            : [],
        parameters: product.parameters
          .map((item) => {
            return {
              name: item.title,
              type: item.type.type,
              value:
                item.type.type === PRODUCT_FORM_TYPE_NUMBER.type
                  ? Number(item.value)
                  : item.value,
            };
          })
          .concat(
            product.article
              ? {
                name: "Артикул",
                type: PRODUCT_FORM_TYPE_TEXT.type,
                value: product.article,
              }
              : null
          )
          .filter((item) => Boolean(item)),
      })
      .then((res) => {
        setProducts((prevVal) =>
          prevVal.map((item) => {
            if (item._id !== res._id) return item;
            return {
              ...item,
              name: res.name,
              description: res.description,
              options: res.options,
              files: res.files,
              price_data: res.price_data,
            };
          })
        );
      })
      .catch((err) => {
        showError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloaderVisible((prevVal) => ({ ...prevVal, save: false }));
      });
  }

  function toggleSubmitPopup() {
    setSubmitPopupOpen((prevVal) => !prevVal);
  }

  function handleDeleteProduct() {
    if (isPreloaderVisible.delete || !user) return;

    const shop_id = user.default_shop._id;
    setDeleteError("");
    setIsPreloaderVisible((prevVal) => ({ ...prevVal, delete: true }));
    mainApi
      .deletePublishedItem({
        shop_id,
        _id: product._id,
      })
      .then(() => {
        setProducts((prevVal) =>
          prevVal.filter((item) => item._id !== product._id)
        );
        setProduct(undefined);
        setInitialProduct(undefined);
        setProductValidity(initialValuesValidity);
        setInitialValidity(initialValuesValidity);
        toggleSubmitPopup();
        navigate(
          `${PLATFORM_MAIN_LINK}/${CATALOG_MAIN_LINK}/${CATALOG_PRODUCTS_LINK}`
        );
      })
      .catch((err) => {
        setDeleteError(parseApiError(err));
      })
      .finally(() => {
        setIsPreloaderVisible((prevVal) => ({ ...prevVal, delete: false }));
      });
  }

  return (
    <>
     <ExportPopup
        isOpen={popups.export}
        handleClose={handleExportClose}
        isPreloaderVisible={isPreloaderVisible.export}
        file={exportFile}
      />
      {width ? (
        width > 880 ? (
          <div className="products-main">
            <PageHeading
              className={"products-main__heading"}
              title={`Товары`}
              actions={clickedSide === 'left' ? actions_left : actions_right}
            />
            <ErrorMessage error={error} />
            {!isPreloaderVisible.main ? (
              <ItemForm
                containerClassName={"products-main__item-from-container"}
              >
                <div className="products-main__two-columns">
                  <div
                    className="products-main__products-list"
                    onClick={() => setClickedSide("left")}
                  >
                    <ProductsList
                      className={""}
                      handleSelect={handleSelect}
                      isSelectable={true}
                      isAllSelected={selectedProducts.all}
                      products={products}
                      selectedExcludeProducts={selectedProducts.exclude}
                      selectedIncludeProducts={selectedProducts.include}
                      name={"products-main"}
                      isPreloaderVisible={isPreloaderVisible.feed}
                      onMoreClick={getMore}
                      isMoreBtnVisible={isMoreBtnVisible}
                    />
                  </div>
                  <div
                    className="products-main__products-form"
                    onClick={() => setClickedSide("right")}
                  >
                    <Routes>
                      <Route
                        index
                        element={
                          <ProductFormPlaceholder withCreateBtn={hasAddPermission} />
                        }
                      ></Route>
                      <Route
                        path={`/${CATALOG_PRODUCTS_ITEM_LINK}/:item_id`}
                        element={
                          <ProductsProductForm
                            product={product}
                            setProduct={setProduct}
                            isPreloaderVisible={isPreloaderVisible.item}
                            isViewOnly={!hasEditPermission}
                            hasAddPermission={hasAddPermission}
                            {...{
                              showError,
                              productValidity,
                              setProductValidity,
                              getItemPublishedById,
                            }}
                          />
                        }
                      ></Route>
                    </Routes>
                  </div>
                </div>
              </ItemForm>
            ) : (
              <div className="drafts-main__preloader">
                <MiniPreloader />
              </div>
            )}
            <SubmitActionPopup
              isSubmitPreloader={isPreloaderVisible.delete}
              hendleSubmit={handleDeleteProduct}
              hendleClose={toggleSubmitPopup}
              isOpen={isSubmitPopupOpen}
              text={"Вы уверены что хотите удалить этот товар?"}
              submitText={"Удалить"}
              cencelText={"Отменить"}
              submitError={deleteError}
            />
          </div>
        ) : (
          <div className="products-main">
            <Routes>
              <Route
                index
                element={
                  <div className="products-main__content">
                    <PageHeading
                      className={"products-main__heading"}
                      title={`Товары`}
                      actions={actions_left}
                    />
                    <ErrorMessage error={error} />
                    {!isPreloaderVisible.main ? (
                      <ProductsList
                        className={""}
                        handleSelect={handleSelect}
                        isSelectable={true}
                        isAllSelected={selectedProducts.all}
                        products={products}
                        selectedExcludeProducts={selectedProducts.exclude}
                        selectedIncludeProducts={selectedProducts.include}
                        name={"products-main"}
                        isPreloaderVisible={isPreloaderVisible.feed}
                        onMoreClick={getMore}
                        isMoreBtnVisible={isMoreBtnVisible}
                      />
                    ) : (
                      <div className="drafts-main__preloader">
                        <MiniPreloader />
                      </div>
                    )}
                  </div>
                }
              ></Route>
              <Route
                path={`/${CATALOG_PRODUCTS_ITEM_LINK}/:item_id`}
                element={
                  <div>
                    <PageHeading
                      className={"import-main__heading"}
                      title={product ? product.title : ""}
                      goBack={{ onClick: () => navigate(-1) }}
                      actions={actions_right}
                    />
                    <ErrorMessage error={error} />
                    <ItemForm>
                      <ProductsProductForm
                        product={product}
                        setProduct={setProduct}
                        isPreloaderVisible={isPreloaderVisible.item}
                        isViewOnly={!hasEditPermission}
                        hasAddPermission={hasAddPermission}
                        {...{
                          showError,
                          productValidity,
                          setProductValidity,
                          getItemPublishedById,
                        }}
                      />
                    </ItemForm>
                    <SubmitActionPopup
                      isSubmitPreloader={isPreloaderVisible.delete}
                      hendleSubmit={handleDeleteProduct}
                      hendleClose={toggleSubmitPopup}
                      isOpen={isSubmitPopupOpen}
                      text={"Вы уверены что хотите удалить этот товар?"}
                      submitText={"Удалить"}
                      cencelText={"Отменить"}
                      submitError={deleteError}
                    />
                  </div>
                }
              ></Route>
            </Routes>
          </div>
        )
      ) : null}
    </>
  );
}

export default ProductsMain;
